
.navbar {
  border: none;
  margin-bottom: 0;
  background-color: white;
  color: #064b74;
  border-radius: 0;
}

.content {
  padding: 50px 0px;
  padding-bottom: 100px;
  width: 80%;
  margin: auto;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #222;
  color: #aaa;
  text-align: center;
  border-radius: 2px 2px 0 0;
  z-index: 100;
  align-content: center;
  padding: 1px;

  @media print {
    display: none;
  }
}

.landing {
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(../assets/landing.jpg) no-repeat center center;
  background-size: cover;
}

.intro-placeholder {
  height: 700px;
}

.form-control {
    box-shadow: none;
}

.home-agency-branding {
  display: inline-block;
  margin-left: 100px;
  color: white;
  padding: 15px;
  font-weight: 500;
}

@media print {
  @page {
      size: auto;   /* auto is the initial value */
      margin: 0;  /* this affects the margin in the printer settings */
  }
}

.glyphicon-exclamation-sign {
  color: #eb644d;
}

label.required:after {
  color: #e32;
  content: ' *';
  display:inline;
}

.tab-content {
  padding-top: 25px;
}

.nav-tabs > li {
  width: 50%;
  text-align: center;
}

/*Form Wizard*/
.bs-wizard {margin-top: 40px;margin-bottom: 40px;padding: 0 0 10px 0;}
.bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {color: #595959; font-size: 15px; margin-bottom: 5px; font-weight: 500}
.bs-wizard > .bs-wizard-step .bs-wizard-info {color: #999; font-size: 14px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 30px; height: 30px; display: block; background: #b2d5f7; top: 45px; left: 50%; margin-top: -15px; margin-left: -15px; border-radius: 50%; cursor:pointer}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ' '; width: 14px; height: 14px; background: #4c9dec; border-radius: 50px; position: absolute; top: 8px; left: 8px; }
.bs-wizard > .bs-wizard-step > .progress {left: 50%; position: relative; border-radius: 0px; height: 8px; box-shadow: none; margin: 20px 0;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: #b2d5f7;}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:0;}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #f5f5f5;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
.bs-wizard > .bs-wizard-step:last-child > .progress {width: 0;}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }

.btn-default {
  text-shadow: none;
  box-shadow: none;
  background-image: unset;
}
